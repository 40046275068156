var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageLayout',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('Header')]},proxy:true},{key:"footer",fn:function(){return [_c('Footer')]},proxy:true}])},[_c('div',{staticClass:"main-page"},[_c('div',{staticClass:"container",style:(("width: " + _vm.getDefaultContainerWidth)),attrs:{"id":"certificates-container"}},[_c('div',{staticClass:"resizer resizer-right"}),_c('div',{staticClass:"resizer resizer-left"}),_c('div',{staticClass:"list-requests"},[(_vm.isLoaded)?[(_vm.getCertificateList.length || _vm.isActiveFilter)?[_c('div',{staticClass:"list-requests-header"},[_c('div',{staticClass:"title"},[[_vm._v("Список истекающих сертификатов")]],2),_c('div',{staticClass:"controls"},[_c('FilterSearch',{attrs:{"pageName":"certificates"}})],1)]),_c('FilterInfoString',{attrs:{"filterInfoString":_vm.getFilterInfoString('certificates')}}),(_vm.getCertificateList.length)?[_c('Table',{staticClass:"list-requests-body"},[_c('Thead',[_c('TableRow',{attrs:{"isTHead":true,"items":_vm.getColumnTitles,"cellsWidth":_vm.getDefaultCellsWidth}})],1),_c('Tbody',{staticClass:"not-clickable"},_vm._l((_vm.getCertificateList),function(items){return _c('TableRow',{key:items[0].id,attrs:{"items":items.map(function (item) {
                      if (!item.hasOwnProperty('controls')) { return item; }
                      var controls = Object.assign({}, item.controls,
                        {refreshButton: Object.assign({}, item.controls.refreshButton,
                          {clickHandler: function () { return _vm.validateCertificate(item.controls.refreshButton.values); }})});
                      return Object.assign({}, item, {controls: controls});
                    }),"cellsWidth":_vm.getDefaultCellsWidth},on:{"click":function () {}}})}),1)],1),(_vm.totalPages !== 1)?_c('div',{staticClass:"pager-wrap"},[_c('Pager',{attrs:{"totalPages":_vm.totalPages,"page":_vm.activePage},on:{"paginate":_vm.changePage}}),_c('div',{staticClass:"select"},[_c('span',[_vm._v("Показывать")]),_c('Select',{attrs:{"name":"page-size","value":_vm.sizePage,"options":[
                      { label: '10', value: 10 },
                      { label: '25', value: 25 },
                      { label: '50', value: 50 }
                    ]},on:{"input":_vm.changeSizePage}}),_c('span',[_vm._v("строк таблицы")])],1)],1):_vm._e()]:_vm._e()]:[_c('div',{staticClass:"list-requests-empty"},[_c('div',{staticClass:"list-requests-empty-content"},[_c('div',{staticClass:"title"},[_c('span',[_vm._v("Здесь будут истекающие сертификаты")])]),_c('div',{staticClass:"descr"},[_c('p',[_vm._v(" Пока что у вас нет ни одного истекающего сертификата. Как только они появятся, можно будет отслеживать их состояние в этом разделе. ")])])])])]]:_vm._e()],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }