<template>
  <PageLayout>
    <template v-slot:header>
      <Header />
    </template>

    <div class="main-page">
      <div class="container" id="certificates-container" :style="`width: ${getDefaultContainerWidth}`">
        <div class="resizer resizer-right"></div>
        <div class="resizer resizer-left"></div>
        <div class="list-requests">
          <template v-if="isLoaded">
            <template v-if="getCertificateList.length || isActiveFilter">
              <div class="list-requests-header">
                <div class="title">
                  <template>Список истекающих сертификатов</template>
                  <!-- <span>Нажмите на строку сертификата для просмотра подробной информации</span> -->
                </div>

                <div class="controls">
                  <FilterSearch pageName="certificates" />
                </div>
              </div>

              <FilterInfoString :filterInfoString="getFilterInfoString('certificates')" />

              <template v-if="getCertificateList.length">
                <Table class="list-requests-body">
                  <Thead>
                    <TableRow
                      :isTHead="true"
                      :items="getColumnTitles"
                      :cellsWidth="getDefaultCellsWidth"
                    />
                  </Thead>
                  <Tbody class="not-clickable">
                    <TableRow
                      v-for="items in getCertificateList"
                      :key="items[0].id"
                      :items="items.map(item => {
                        if (!item.hasOwnProperty('controls')) return item;
                        const controls = {
                          ...item.controls,
                          refreshButton: {
                            ...item.controls.refreshButton,
                            clickHandler: () => validateCertificate(item.controls.refreshButton.values)
                          }
                        };
                        return {...item, controls};
                      })"
                      :cellsWidth="getDefaultCellsWidth"
                      @click="() => {}"
                    />
                  </Tbody>
                </Table>
                <div v-if="totalPages !== 1" class="pager-wrap">
                  <Pager @paginate="changePage" :totalPages="totalPages" :page="activePage" />
                  <div class="select">
                    <span>Показывать</span>
                    <Select
                      name="page-size"
                      :value="sizePage"
                      @input="changeSizePage"
                      :options="[
                        { label: '10', value: 10 },
                        { label: '25', value: 25 },
                        { label: '50', value: 50 }
                      ]"
                    />
                    <span>строк таблицы</span>
                  </div>
                </div>
              </template>
            </template>

            <template v-else>
              <div class="list-requests-empty">
                  <div class="list-requests-empty-content">
                    <div class="title">
                      <span>Здесь будут истекающие сертификаты</span>
                    </div>
                    <div class="descr">
                      <p>
                        Пока что у вас нет ни одного истекающего сертификата. Как только они появятся, можно будет отслеживать их состояние в этом разделе.
                      </p>
                    </div>
                  </div>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>

    <template v-slot:footer>
      <Footer />
    </template>
  </PageLayout>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapState, mapGetters } from "vuex";

import {
  Header,
  Footer,
  FilterSearch,
  FilterInfoString
} from "@/components";
import {
  Button,
  Table,
  Thead,
  Tbody,
  TableRow,
  Pager,
  IconBase
} from "@/components/UI";
import PageLayout from "@/components/layouts/PageLayout.vue";
import Select from "@/components/UI/form/Select.vue";
import Plus from "@/components/UI/icon/Plus.vue";
import { MIN_CONTAINER_WIDTH } from "@/constants";
import ROLES from "@/config/roles";
import { PAGE_SHORT_NAMES } from '@/config/pages';
import TABLE_COLUMNS from '@/config/table-columns';

export default Vue.extend({
  name: 'Certificates',

  components: {
    Button,
    Tbody,
    Thead,
    Table,
    TableRow,
    Select,
    Pager,
    PageLayout,
    Header,
    Footer,
    IconBase,
    Plus,
    FilterInfoString,
    FilterSearch
  },

  computed: {
    ...mapState("certificateListState", [
      "sizePage",
      "activePage",
      "totalPages",
      "isLoaded",
    ]),
    ...mapGetters("certificateListState", ["getCertificateList"]),
    ...mapGetters("tableState", ["getColumnTitles"]),
    ...mapGetters("authorizationState", ["getRole"]),
    ...mapGetters("filterState", ["getFilterInfoString", "isActiveFilter"]),

    getDefaultContainerWidth() {
      return window.localStorage.getItem(`certificates-container-width`) ||
      (MIN_CONTAINER_WIDTH + "px");
    },

    getDefaultCellsWidth() {
      const columns = TABLE_COLUMNS[PAGE_SHORT_NAMES.certificates][this.getRole] ?? TABLE_COLUMNS[PAGE_SHORT_NAMES.certificates][ROLES.ChiefManager];

      return columns.map((column, index) => (
        window.localStorage.getItem(`certificates-column-${index}-width`) ||
        column.part * 100 + "%"
      ))
    },
  },
  methods: {
    ...mapActions("certificateListState", [
      "getList",
      "changePage",
      "changeSizePage",
      "setActivePage",
      "validateCertificate",
    ]),
    ...mapActions("tableState", ["setTableContentType"]),
    ...mapActions("filterState", ["parseFilterFromLocaleStorage"]),
  },

  beforeMount() {
    this.setTableContentType(PAGE_SHORT_NAMES.certificates);
  },

  mounted() {
    this.parseFilterFromLocaleStorage("certificates");
    this.getList();
  },
})
</script>

<style lang="scss">
#certificates-container {
  position: relative;
  min-width: 1400px;
  max-width: 1900px;
  .resizer {
    position: absolute;
    height: 100%;
    top: 0;
    width: 5px;
    cursor: col-resize;
    &.resizer-right {
      right: 5px;
    }
    &.resizer-left {
      left: 5px;
    }
  }  
}

.list-requests {
  margin-top: 10px;

  .pager-wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 45px;

    .select {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 17px;

      .form-item-select {
        &__control {
          height: auto;
          background: none;
          border: none;
        }

        &__container {
          padding: 0 0 0 5px;
        }

        &__value {
          color: #1e72c2;
        }

        &__indicators {
          span::before {
            border-color: #1e72c2;
            border-width: 0 2px 2px 0;
          }
        }

        &__input {
          input {
            height: 0;
            width: 0;
          }
        }
      }
    }
  }

  .form-wizard-content--progress {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -21px 0 0 -27px;
  }

  a.button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    padding: 0 20px;
    background: linear-gradient(263.67deg, #7a6afa 1.17%, #607aff 99.07%);
    border-radius: 4px;
    color: #ffffff;
    text-decoration: none;
    text-transform: uppercase;

    .icon-plus {
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-right: 5px;
      background: url("/img/icon/plus.svg") no-repeat center/cover;
    }
  }

  .form-item.search {
    .input-wrap {
      position: relative;
      width: 425px;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: 15px;
        margin-top: -6px;
        opacity: 1;
        width: 12px;
        height: 12px;
        background: url("/img/icon/search.svg") no-repeat center;
      }
    }

    input {
      padding-right: 35px;
    }
  }

  &-empty {
    width: 580px;
    margin: 170px auto 0;
    text-align: center;

    .title {
      font-weight: 700;
      font-size: 26px;
      color: #000;
    }

    .descr {
      line-height: 25px;
    }

    .controls {
      margin-top: 40px;

      .button {
        display: inline-flex;
      }
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .controls {
      display: flex;
      align-items: center;

      & > * + * {
        margin-left: 15px;
      }
    }

    .title {
      font-size: 25px;
      line-height: 43px;
      color: #000;

      span {
        display: block;
        font-size: 12px;
        line-height: 21px;
        color: #808080;
      }
    }
  }
}
</style>
